.pdf-report-form {
  display: flex;
  width: 95%;
  flex-direction: column;
  margin: 0;
  padding: 20px;
  background-color: white;
}

.pdf-report-heading-conatiner{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-container {
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 100%;
}
.footer-text {
  font-size: 11px;
  text-align: center;
  padding-top: 15px;
  display: inline-block;
  color: black !important;
}
.mt-auto {
  margin-top: auto;
}
.date-pageNo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
}
.date-time-container {
  border-right: 1px solid black;
  padding-right: 8px;
  margin-right: 8px;
}
.date-text {
  font-size: 11px;
  margin: 5px;
}
.page-no-text {
  font-size: 12px;
}

.pdf-logos-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
}
.pdf-footer-logo {
  width: 80px;
  margin: 7px;
}

.pdf-report-button{
  display:flex;

}

.report-buttons-container{
  display: flex;
  position: fixed;
  right:0px;
  width:20%;
  justify-content: space-between;
}

.home-button,.pdf-button{
  height: 40px;
  width: 135px;
  background-color: var(--ApolloGreen);
  color: white;
  border: 1px solid white;
}

.pdfreportform {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdfpage {
  width: 210mm;
  height: 290mm;

  padding: 10px 10px;
  border: 1px solid rgb(185, 185, 185);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.pdf-header-conatiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.pdf-apollo-logo {
  width: 80px;
}

.RiskScoreHeading {
  font-size: 26px;
  font-weight: 600;
}

.pdf-report-patient-detials-container {
  width: 100%;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.user-details {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  height: 70px;
  padding: 5px 15px;
  border-left: 5px solid #f2b12a;
  margin-bottom: 15px;
}

.user-details li {
  width: 33.3%;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.pateint-info-heading {
  font-size: 14px;
  font-weight: 600;
  width: 70px;
}

.patient-info-value {
  font-size: 14px;
  margin-left: 8px;
  color: #17748f;
  text-transform: capitalize;
}

.info-container {
  display: flex;
  align-items: center;
  width: 33%;
  padding: 8px;
}
.info-title {
  font-size: 14px;
  font-weight: 600;

  width: 30%;
}
.info-colon {
  font-size: 14px;
  font-weight: 600;
  width: 5%;
}
.info-value {
  font-size: 14px;
  font-weight: 600;
  width: 60%;
}

.width-65 {
  width: 65%;
}

.attributes-info-container {
  border: 1px solid #17748f;
  border-radius: 8px;
}

.box-heading {
  width: 250px;
  background-color: #ff4b23;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 14px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 10px;
  margin-bottom: 6px;
  margin-top:-2px;
}

.attribute-info-bg {
  background-color: #17748f;
}

.attribute-border{
    border: 1px solid #17748F;

}

.field-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 6px 10px;
}

.field-text {
  font-size: 13px;
}

.field-date-container {
    width: 100px;
    min-height: 23px;
    border: 1px solid #17748F;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 13px;
    text-transform: capitalize;
  }

  .pdf-attributes-fields-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding:0px;
  }

  .prediabetes-riskbox{
    margin: auto;
    width:60%;
  }