

.score-box-heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
    padding-left: 20px;
}


.liver-each-score-box{
    width:33.3%;
}
.liver-score-conatiner{
    width:100%;
}