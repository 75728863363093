* {
  margin: 0;
  padding: 0;
}

.imageContainer > img {
  width: 100%;
}
#quickNav {
  display: flex;
  list-style: none;
  margin-left: auto;
  height: 100%;
  align-items: center;
  justify-content: center;
}
#quickNav > li {
  font-size: 2em;
  font-weight: 600;
  margin: 0 2em 0 2em;
  color: #7c7c7c;
  cursor: pointer;
  text-wrap: nowrap;
}
#quickMobileNav {
  display: none;
}
#coverImageContainer {
  width: 100%;
}
#coverImageContainer > img {
  width: 100%;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer-block {
  flex-direction: column;
  padding-bottom: 40px;
}
#abutImageContainer,
#validationImageContainer {
  width: 45%;
}
.textConent {
  font-size: 1.5em;
  width: 40%;
}
.points {
  list-style: circle;
  display: flex;
  flex-direction: column;
}
.bigPoints {
  font-size: 0.7em;
}
.points > li {
  margin: 0.7em 0 0.7em 0;
  list-style: none;
}
.points > h3 {
  font-size: 1.5em;
}
.bigPoints > h3 {
  font-size: 1.9em;
}
#validationTextcontent {
  justify-content: space-around;
}
#Research {
  font-size: 1.6em;
  width: 100%;
  box-sizing: border-box;
  padding: 3em 5em 3em 5em;
}
#FaqImageContainer {
  height: auto;
  width: 30%;
}
#FAQContainer {
  justify-content: space-evenly;
}
#Research li {
  line-height: 1.5em;
}
#TakeToMeApp {
  background-color: rgb(103, 84, 150);
  margin-top: 1em;
  padding: 20px;
  font-size: 16px;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
}
#footer {
  color: black;
  font-size: 16px;
  padding-top: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* src/App.css */

.app-loading-container {
  width: 100%;
  height: 100vh;
}

.landing-navigation-buttons {
  display: flex;
  height: 40px;
  margin-top: 20px;
}

.footer-button-container {
  display: flex;
  width: 25%;
  justify-content: space-around;
  margin: 20px;
}
.privacy-button {
  background-color: transparent;
  color: white;
  text-decoration: dashed;
  border: none;
  outline: none;
  margin-right: 10px;
  font-size: 16px;
}
.bg-footer-brown {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.footer-click {
  color: black;
  text-decoration: none;
  font-size: 16px;
}
.footer-click:hover {
  background-color: transparent;
  color: #5fa8d3;
}

.d-none {
  display: none;
}

.app-popup-main-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.app-popup-container {
  background-color: white;
  width: 90%;
  height: 100% !important;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
}
.app-popup-content-heading {
  font-size: 26px;
  font-weight: 600;
  padding: 20px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.app-popup-content-container {
  flex-grow: 1;
  overflow: auto;

  padding: 20px;
}
.app-popup-close-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.2);
  padding: 10px;
}
.app-popup-close-button {
  padding: 8px 16px;
  background-color: rgb(230, 230, 230);
  border-radius: 8px;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;

  transition: all 200ms ease-in-out;
  color: rgb(29, 31, 29);
}
.app-popup-close-button:hover {
  background-color: rgb(214, 214, 214);
}
.app-popup-text-highlight-text {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
}
.app-popup-list-container {
  list-style-type: number;
  padding-left: 20px;
}
.app-popup-list-container li {
  font-size: 13px;
  margin-bottom: 10px;
}
.app-popup-text {
  font-size: 13px;
  margin-bottom: 12px;
}

.login-icon {
  width: 30px;
  animation: spin 2.5s linear 0s infinite normal;
}

/* 
@media (max-width: 900px) {

  #TakeToMeApp {
    font-size: 1.5em;
  }
  .container {
    flex-direction: column;
  }
  .textConent {
    font-size: 1.2em;
    width: 90%;
    padding: 1em;
  }

  .points > li {
    margin: 0.7em 0 0.7em 1.2em;
  }
  #Research {
    font-size: 1.3em;
    width: 100%;
    box-sizing: border-box;
    padding: 2em 1em 3em 1em;
  }
  #abutImageContainer,
  #validationImageContainer {
    width: 74%;
  }
  #FaqImageContainer {
    height: auto;
    width: 90%;
  }
  .mobileImg {
    display: block;
  }
  .desktopimg {
    display: none;
  }
  #navBar {
    height: 10vh;
  }
  #quickNav {
    display: none;
  }
} */

.app-info-landing-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: white;
  margin: auto;
}

.apollo-image-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  width: 100%;
}

.main-inner-container {
  width: 90%;
  margin-left: 7%;
}

.main-section-inner-block {
  margin-left: 7%;
}

.heading-bar-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 7%;
  /* background-color: #f2ecee; */
  height: 100vh;
}

.apollo-image {
  width: 70px;
  height: 60px;
}
.landingpage-action-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7px;
  cursor: pointer;
  margin-bottom: 7px;
}

.app-landing-page-icon-container {
  width: 60px;
  height: 20px;
  padding: 5px;
  border-radius: 24px;
  text-align: center;
  margin: 5px;
}

.icon-landing {
  width: 20px;
  height: 20px;
}

.nav-link-buttons {
  margin-top: 5px;
}
.app-landing-page-icon-container:hover {
  background-color: #e8e0e8;
}

.list-of-redirect-elements {
  display: flex;
  flex-direction: column;
  list-style: none;
  position: relative;
  top: 2%;
}
.list-of-redirect-elements > li {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-wrap: wrap;
}

.logout-button-container-block {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 1%;
}
.logout-button {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  margin-top: 5px;
}

.about-us-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  font-weight: 600;
  font-size: 18px;
}

.each-individual-section {
  display: flex;
  flex-direction: column;
  margin: 80px 0px;
}

.section-block-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

.home-page-content-container {
  width: 38%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}
.heading-apollo-text {
  font-size: 46px;
  color: #5fa8d3;
  font-weight: 500;
  text-align: center;
}

.home-page-image-container {
  width: 60%;
}

.copd-home-page-image {
  border-radius: 30px;
  width: 100%;
  height: 450px;
}

.about-us-container {
  height: 60px;
  padding: 0 30px;
  transition: all 0.2s cubic-bezier(0.2, 0, 0, 1);
  border: 0;
  border-radius: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  cursor: pointer;
  margin-top: 25px;
}

.landing-content-container {
  width: 50%;
  padding-right: 20px;
}

.image-container-block {
  position: sticky;
  top: 20px; /* Adjust as needed */
  display: flex;
  align-items: center;
  width: 40%;
}

.imterpretation-image-container-block {
  position: sticky;
  top: 0;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.why-image {
  width: 350px;
  height: 200px;
  border-radius: 15px;
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.image-1-block {
  position: absolute;
  top: 45%;
  z-index: 1;
  /* left: -10%; */
  margin: -35px;
}

.image-container-block .why-image-container:last-child {
  position: relative;
  z-index: 0;
  top: -10%;
  margin-left: auto;
}

.landing-list {
  list-style: none;
  line-height: 2;
  border-radius: 20px;
  padding: 20px;
}

.landing-list > li,
.link-text {
  font-size: 16px;
  padding: 3px 15px 3px 0px;
  margin: 10px 0px 10px 0px;
  border-radius: 30px;
  text-align: justify;
}

.link-point-img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.landing-list-point {
  border-radius: 30px;
  text-align: justify;
}
.each-section-heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.sub-points-list-container {
  list-style: none;
  margin-left: 20px;
}

.section-3-list {
  font-size: 16px;
  list-style: none;
}
.section-3-list > li {
  padding: 3px 15px 3px 0px;
  margin: 10px 0px 10px 0px;
  border-radius: 30px;
  text-align: justify;
}

/* .list-style-type{
  padding-left:20px;
  list-style-type: decimal;
  border:1px solid red;

} */

.section3-point {
  font-size: 16px;
  padding: 3px 15px 3px 0px;
  border-radius: 30px;
  text-align: justify;
}

.inner-sub-heading {
  font-size: 16px;
}

.intepretation-image {
  width: 100%;
  height: 450px;
  max-height: 100vh;
  border-radius: 15px;
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.cerficate-image {
  height: 470px;
  width: 400px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.certificate-note-point {
  font-size: 16px;
  font-weight: 500;
}
.checkbox-container {
  position: relative;
  margin-top: 10px;
}
.checkbox-input {
  height: 20px;
  width: 20px;
  cursor: pointer;
  opacity: 0;
}
.custom-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid #d9d5d5;
  pointer-events: none;
  background-image: linear-gradient(white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -30%;
}
.custom-checkbox_border_radius {
  border-radius: 50%;
}
.checkbox-input:checked ~ .custom-checkbox {
  background-color: rgb(103, 84, 150);
  border: 2px solid white;


}

.margin-top-10 {
  margin-top: 10px;
  margin-left: 20px;
}

.align-flex-start {
  align-items: flex-start;
}

.black-color {
  color: black;
}
