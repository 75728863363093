.report-form-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
}

.risk-box-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 90%;
}

.score-box-heading {
  font-weight: 600;
  margin-bottom: 7px;
  padding-left: 20px;
}

.score-box-text {
  font-weight: 600;
}
.risk-text {
  color: black;
}

.score-box {
  border-radius: 8px;
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  color: white;
  margin-bottom: 30px;
  border: 3px solid white;
  width: 70%;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2),
    inset 0px 3px 4px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.report-para-text {
  font-size: 14px;
  line-height: 1.6;
}

.note-text {
  margin-top: 10px;
}

.each-risk-box {
  width: 25%;
  box-sizing: border-box;
}

.risk-factors {
  border-radius: 8px;
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  margin-bottom: 30px;
  border: 3px solid white;
  width: 20%;
  height: 30px;
  text-align: center;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2),
    inset 0px 3px 4px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.right-line {
  width: 15%;
  height: 3px;
  border-radius: 10px;
  background-image: linear-gradient(to right, black, white);
}
.left-line {
  width: 15%;
  height: 3px;
  border-radius: 10px;
  background-image: linear-gradient(to right, white, black);
}

.report-heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  margin-top: 20px;
}

.report-sub-heading {
  font-size: 20px;
  font-weight: 600;
  color: rgb(103, 84, 150);
}

.risk-card-bg-green {
  background-color: rgb(0, 146, 0) !important;
  color: white !important;
}
.risk-card-bg-yellow {
  background-color: rgb(241, 177, 0) !important;
  color: white !important;
}
.risk-card-bg-red {
  background-color: rgb(255, 81, 50) !important;
  color: white !important;
}

.font-color {
  color: black;
  text-align: center;
}

.report-recommended-list {
  list-style-type: none;
  padding: 0px;
}
.report-recommended-list-item-heading {
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
}
.report-recommended-list-item-value {
  margin-bottom: 25px;
  list-style: none;
  font-size: 14px;
}

.container-div {
  width: 70%;
  background-color: white;
}

.disclaimer-list-item {
  font-size: 14px;
  list-style: none;
  margin-top: 12px;
}
/* 
.risk-box-container-margin {
  margin-left: 10%;
} */
