.list-of-scrolling-items {
  width: 97%;
  margin-left: 10%;
  height: 90%;
}

.front-page-container {
  width: 90%;
  height: 100%;
}

.popup-main-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.close-button {
  border: none;
  outline: none;
  padding: 12px 16px;
  font-weight: 500;
  margin-bottom: 8px;
  border-radius: 6px;
  cursor: pointer;
}

.warring-popup-container {
  width: 300px;
  min-height: 200px;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.popup-text-highlight-text {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  user-select: none;
}

.popup_buttons {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin-top: 35px;
}

.info-button {
  height: 50px;
  padding: 0 30px;
  transition: all 0.2s cubic-bezier(0.2, 0, 0, 1);
  border: 0;
  border-radius: 48px;
  background-color: #6442d6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  cursor: pointer;
  margin:20px;
  color:white;
}


.contentBox {
  height: 45%;
  display: grid;
  grid-gap: 8px;
  gap: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-flow: row;
  margin-top: 20px;
}

.slider-block-container {
  height: 100%;
}

.leftSection {
  display: flex;
  margin: 0;
  border-radius: 24px;
  background: #f8f1f6;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  height: 95%;
  border-radius: 24px;
}

.left-section-content {
  display: flex;
  flex-direction: column;
}

.rightSection {
  position: relative;
  overflow: hidden;
  height: 95%;
  border-radius: 24px;
}

.contentImg-landing {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.contentBox {
  transition: background-color 0.3s;
}

.get-started-button-container {
  height: 60px;
  padding: 0 30px;
  transition: all 0.2s cubic-bezier(0.2, 0, 0, 1);
  border: 0;
  border-radius: 48px;
  background-color: #6442d6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-down {
  animation: slideDown 1s ease-out forwards;
}

@media screen and (min-width: 720px) and (max-width: 1300px) {
  .slider-block-container {
    height: 550px;
  }
  .contentBox {
    height: 520px;
  }
  .leftSection {
    height: 500px;
  }
  .rightSection {
    height: 500px;
  }
}

@media screen and (min-width: 1900px) {
  .leftSection {
    height: 85%;
  }
  .rightSection {
    height: 85%;
  }
  .left-section-content {
    padding: 70px;
  }
}
