.Table-of-Content {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  margin-left: 10%;
  z-index: 4px;
  width: 97%;
  background-color: #f8f1f6;
  border-radius: 40px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 50px;
  margin-top: 10px;
}

.Table-Of-ContentItem {
  list-style: none;
  font-size: 20px;
  display: inline-flex;
  position: relative;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  border: none;
  border-radius: 40px;
  line-height: 50px;
  text-decoration: none;
  text-decoration: initial;
  cursor: pointer;
  vertical-align: middle;
  z-index: 1;
}

/* .Table-Of-ContentItem:hover {
  color: var(--ApolloGreen);
  font-weight: 600;
} */

#activeItem {
  background-color: #dcdaf5;
}
