.loader-container {
  position: absolute;
  top: 30%;
  right: 50%;
  height: min-content;
  width: min-content;
}
.CatagoryName {
  font-size: 18px;
}
#boxForRadioButtonInAttributesPage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
#boxForRadioButtonInAttributesPage .anRadioOption {
  margin: 1em 0 1em 0em;
}
.checkbox_input_box_in_input_component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.checkbox_input_box_in_input_component .lable_in_input_component {
  font-size:16px;
  margin: 0 0 0 20px;
}
.checkbox_lable_box_input_in_input_component {
  width: fit-content;
  margin: 0 0 0 0;
}
#Medical_History {
  display: flex;
  flex-direction: column;
}
#Lifestyle {
  display: flex;
  flex-direction: column;
}
.inputbox p {
  font-size: 1em;
  margin-left: 2px;
}
.inputbox > div > label {
  font-size:16px;
}
.inputbox > label {
  font-size:16px;
}
.anRadioOption > label {
  font-weight: 400 !important;
}
.sub-option-container {
  margin-left: 3.5em;

}
.sub-option-container .checkbox_lable_box_input_in_input_component {
  padding-left: 0;
}
.TitleAttributePage {
  font-size: 16px;
  font-weight: 500;
  margin-left: 1em;
  display: flex;
  align-items: center;
}
.anRadioOption {
  margin: 0.5em 0 0.5em 0em;
}

.anRadioOption > label {
  margin-left: 0.5em;
}
.InputFormInAttributePage {
  padding: 2em;
}
.checkbox_lable_in_input_component {
  font-weight: 400 !important;
}
#boxForRadioButtonInAttributesPage + .inputbox > div > label {
  font-weight: 400 !important;
  margin-left: 0.7em;
}
.anRadioOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
}
#boxForRadioButtonInAttributesPage label {
  cursor: pointer;
}
#generatePdfButton {
  background-color: var(--appolloGreen);
  margin: 1em;
  margin-bottom: 3em;
  padding: 1em 2em 1em 2em;
  font-size: 1.5em;
  color: white;
  border: none;
  outline: none;
  border-radius: 10px;
}
.warring-popup-container {
  min-width: 250px;
  max-width: 600px;
  min-height: 220px;

  border-radius: 8px;
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: white;
}
.warning-icon {
  width: 30px;
}
.warring-popup-content-heading {
  font-size: 20px;
  color: red;
  text-align: center;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  user-select: none;
}
.popup-text-highlight-text {
  font-size: 20px;
  text-align: center;
  user-select: none;
}
.warring-button {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  padding: 14px 30px;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background: linear-gradient(to right, #456d76, #bcdfe7);
  color: rgb(35, 37, 35);
  font-weight: 600;
  margin: 15px;
}

.sub-option-radio {
  display: flex;
  align-items: center;
}

.radio-button-suboption-label{
  margin-left:15px;
}

.sub-option-of-radio-button {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left:25px;
}

.checkbox-children{
  margin-left:20px
}
