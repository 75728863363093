.popUpBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.137);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: hidden;
}
.popUPContentBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: min-content;
  max-width: 95%;
}
.ClosePopUpButton {
  height: 40px;
  padding: 0 30px;
  border-radius: 10px;
  border: 0;
  background-color: rgb(103, 84, 150);
  color: white;
  transition: all 0.2s cubic-bezier(0.2, 0, 0, 1);
  border: 0;
  cursor: pointer;
  font-weight: 600;
}
