.text_input_in_input_component_container {
  width: 15em;
  height: 3em;
  border: 1px solid #3b393e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
  background-color: white;
  margin-top: 25px;
}

.text_input_in_input_component {
  width: 90%;
}

.text_name_and_icon_container {
  display: flex;
  position: relative;
  width: 100%;
}

.text_name_and_icon_container input {
  width: 100%;
  height: 86%;
  padding: 12px 16px;
  border-radius: 4px;
  outline: none;
  border: none;
  margin-left: 5px;
  font-size: 16px;
}

.text_name_and_icon_container label {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px;
  transition: all 0.3s ease;
  pointer-events: none;
  color: #3b393e;
  font-size: 14px;
}

.text_name_and_icon_container:focus-within label,
.text_name_and_icon_container input:not(:placeholder-shown) + label {
  top: -3px;
  left: -15px;
  font-size: 12px;
  border-radius: 4px;
  padding: 0 8px;
}

.icons-format {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.select-icons {
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 5px;
}

.inputbox {
  padding: 0.2em 0.5em 0.2em 0.5em;
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: relative;
}

.description-input-container {
  display: flex;
  flex-direction: row;
}

.optionsforRadioBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.font-size {
  font-size: 20px;
}

.radio_in_input_component {
  width: 1.3em;
  height: 1.3em;
}

.radio-button {
  width: 20px;
  height: 20px;
  opacity: 0;
  position: relative;
  right: -20px;
}
.c-radio {
  width: 20px;
  height: 20px;
  border: 2px solid #d9d5d5;
  border-radius: 50%;
  pointer-events: none;
  position: relative;
}

input:checked ~ .c-radio {
  background-color: #675496;
  border: 2px solid white;

}
.date_in_input_component {
  width: 15.5em;
  height: 3em;
  border: 1px solid #3b393e;
  border-radius: 5px;
  margin: 0.5em;
  background-color: white;
  margin-top: 25px;
  padding: 12px 16px;
  outline: none;
}
.optionsforRadioBox label {
  margin: 0 0.5em 0 0.5em;
  font-size: 1em;
}

.inputbox > label {
  margin-bottom: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 270px;
  background-color: #555;
  color: #fff;
  text-align: left;
  padding: 25px;
  border-radius: 6px;
  font-size: 12px;
  margin-bottom: 5px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip {
  background-color: transparent;
  width: 10px;
  height: 10px;

  border: 1px solid rgb(66, 66, 66);

  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  border-radius: 50%;
  font-size: 6px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: italic;
  margin-left: 5px;
  position: relative;
  top: -4px;
  left: 5px;
}
.tooltip-list-container {
  list-style-type: number;
  padding-left: 18px;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 25%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  /* border-color: rgb(168, 0, 0) transparent transparent transparent; */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  /* background-color: rgb(168, 0, 0); */
  color: white;
  font-style: normal;
}

.star-icon {
  width: 8px;
  margin-right: 4px;
}

.age-error-text {
  background-color: rgb(255, 25, 25);
  color: white;
  position: absolute;
  top: 103%;
  font-size: 9px;
  border-radius: 30px;
  min-width: 150px;
  max-width: 200px;
  text-align: center;
  display: none;
  padding: 8px;
  z-index: 5;
}

.form-error-text-valid {
  background-color: rgb(255, 25, 25);
  color: white;
  position: absolute;
  top: 103%;
  font-size: 9px;
  border-radius: 30px;
  min-width: 150px;
  max-width: 200px;
  text-align: center;
  padding: 8px;
  z-index: 5;
}

.form-error-text-valid::before {
  content: "";
  width: 8px;
  height: 8px;
  display: block;
  background: rgb(255, 25, 25);
  position: absolute;
  bottom: 100%;
  left: 20%;
  transform: translate(100%, 50%) rotate(45deg);
  z-index: 5;
}
.form-error {
  background-color: rgb(255, 25, 25);
  color: white;
  position: absolute;
  top: 103%;
  font-size: 9px;
  border-radius: 30px;
  min-width: 150px;
  max-width: 200px;
  text-align: center;
  padding: 8px;
  z-index: 5;
}

@keyframes error-fade-in {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
@keyframes error-fade-out {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}
.age-error-text::before {
  content: "";
  width: 8px;
  height: 8px;
  display: block;
  background: rgb(255, 25, 25);
  position: absolute;
  bottom: 100%;
  left: 20%;
  transform: translate(100%, 50%) rotate(45deg);
  z-index: 5;
}

.checkbox-input-box {
  margin: 0.5em;
  display: flex;
  font-size: 1.5em;
  position: relative;
  display: flex;
  align-items: center;
}

.button-group {
  display: flex;
}

.button-group button {
  background-color: rgb(103, 84, 150);
  color: white;
  padding: 10px 20px 10px 20px;
  margin: 5px;
  cursor: pointer;
  height: 3em;
  border: 1px solid #3b393e;
  border-radius: 5px;
}

@media (max-width: 900px) {
  .text_input_in_input_component {
    width: 80%;
  }
}

@media (max-width: 900px) {
  #inputbox > input {
    margin-left: auto;
  }

  .text_input_in_input_component {
    width: 60%;
  }

  .inputbox {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0.5em 0 0.5em 0;
    flex-wrap: wrap;
  }
  .checkbox_input_box_in_input_component {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  .inputbox > label {
    height: 100%;
    margin: 0;
  }
  .optionsforRadioBox label {
    margin: 0 0.5em 0 0.5em;
    font-size: 0.9em;
  }
  .optionsforRadioBox {
    flex: 1;
    max-width: 60%;
  }
}

.radioOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
}

.radioOptions > input {
  height: 25px;
  width: 25px;
}

.select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  width: 12em;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 0.5em 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  position: relative;
}

.select_in_input_component {
  width: 100%;
  border: none;
  background: transparent;
  font-size: 1em;
  cursor: pointer;
  outline: none;
  appearance: none; /* Removes the default dropdown arrow */
}

.select-box::after {
  content: "\25BC";
  position: absolute;
  right: 1em;
  pointer-events: none;
  font-size: 0.8em;
  color: #666;
}

.select_in_input_component option {
  padding: 0.5em;
  background-color: white;
  color: #333;
  border: none;
}

.select_in_input_component option:hover {
  background-color: #f1f1f1;
}

.subOptionsBox > .inputbox {
  padding: 0;
}
.subOptionsBox > .inputbox > input {
  /* padding:0; */
  /* padding-left: 3em; */
  margin: 0.5em 0 0.5em 0.2em;
}
.subOptionsBox > .inputbox > .checkbox_lable_box_input_in_input_component {
  padding: 0;
  padding-left: 0.5em;
}
.checkbox_box_in_input_component {
  width: 0.7em;
  height: 0.7em;
}
.anRadioOptionInINputcomponent label {
  cursor: pointer;
}
.anRadioOptionInINputcomponent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-bar {
  width: 17px;
  height: 17px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: transparent;
}

.definition-label-container {
  display: flex;
  align-items: center;
}
.description-info-button-container {
  margin-left: 10px;
}

.custom-dropdown {
  position: relative;
  border: 1px solid #3b393e;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
  cursor: pointer;
  width: 15em;
  height: 1.5em;
  margin: 0.5em;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-icons {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.selected-item {
  font-size: 16px;
  color: black;
  flex-grow: 1;
  overflow: hidden;
  height: 25px;
}

.dropdown-list {
  display: none;
  position: absolute;
  width: 100%;
  max-height: 150px; /* Limit the height of the dropdown */
  overflow-y: auto; /* Add scroll for overflow */
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #a390ff;
  border-radius: 5px;
  background-color: #ffffff;
  z-index: 10;
}

.dropdown-list.show {
  display: block;
}

.dropdown-item {
  padding: 10px;
  color: #4d4d4d;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-item:hover,
.dropdown-item.active {
  background-color: #e6e0ff;
}

.dropdown-arrow {
  font-size: 12px;
  margin-left: 5px;
  transition: transform 0.2s;
}
