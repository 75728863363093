@import url('https://fonts.googleapis.com/css2?family=Google+Sans+Text:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: "Google Sans Text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
