.report-form-page-conatiner {
  display: flex;
  flex-direction: column;
}

.generate-button-div {
  display: flex;
  margin: auto;
}

.font-size {
  font-size: 14px;
}
