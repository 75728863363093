#concentForm {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 90vh;
  overflow-y: auto;
  scroll-behavior: auto;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 3em 5% 3em 7%;
  font-size: 2em;
  cursor: auto;
  line-height: 1.6;
}
#concentForm li {
  list-style: decimal;
  margin-left: 2em;
}
#concentForm p {
  margin: 0.5em 0 0.5em 0;
  line-height: 1.6;
}

.PopUpForPaitentConcent {
  font-size: 1.3em;
  line-height: 2;
  margin-top: 4em;
}

.heading-level-1{
  font-size: 18px;
}
.heading-level-2{
  font-size: 16px;
}

.heading-level-3{
  font-size: 14px;
}

.heading-level-4{
  font-size: 12px;
}

.heading-level-5{
  font-size: 10px;
}

.heading-level-6{
  font-size: 8px;
}
