:root {
  --ApolloGreen: #237d9e;
  --paraTextClr: #5a5a5a;
  --ApolloYellow: #ffd56f;
}
#MegaContainer {
  font-size: 20px;
}
@media (max-width: 1500px) {
  #MegaContainer {
    font-size: 18px;
  }
}

@media (max-width: 1300px) {
  #MegaContainer {
    font-size: 16px;
  }
}

@media (max-width: 1100px) {
  #MegaContainer {
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  #MegaContainer {
    font-size: 11px;
  }
}

#BodyContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
}

#avtiveContent {
  color: var(--ApolloGreen);
  font-weight: 400;
}
#ContentContainer {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.contentBoxs {
  max-width: fit-content;
  height: auto;
  display: flex;
  box-sizing: border-box;
  margin: 0 0 2em 0;
  box-sizing: border-box;
  margin: 1em 0 2em 0;
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.contentBoxs * {
  box-sizing: border-box;
}
.textContent {
  width: 55%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-evenly;
}
.textTitle {
  font-size: 46px;
  text-decoration: none;
  font-weight: 500;
  color: black;
}
/* .textTitle:hover {
  text-decoration: underline;
} */
.textPara {
  margin: 1em 0 0.7em 0;
  font-weight: 300;
  font-size: 1em;
  color: #1c1b1d;
  line-height: 1.8;
  text-align: justify;
}
.contentImg {
  width: 35%;
  height: 100%;
}
.contentImg::before {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1) 1000%
  );
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.contentImg img {
  width: 100%;
  max-height: 20em;
  max-width: max-content;
  background-position: center;
  background-size: cover;
  border-radius: 30px;
}
.OpenButton {
  font-size: 1em;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  text-decoration: none;
}
.lowercontent {
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: flex-start;
}
.upperContent {
  width: 95%;
  display: flex;
  align-items: center;
  height: auto;
  justify-content: space-between;
}
.line {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4%;
}
.line div {
  width: 60%;
  border: 1px solid #c0c0c0;
}

.leftcontentBoxs .contentImg {
  width: 35%;
  padding-right: 3em;
}
.leftcontentBoxs .lowercontent {
  justify-content: flex-end;
  padding-right: 15%;
}
.leftcontentBoxs .lowercontent .line {
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 4%;
}
.leftcontentBoxs .lowercontent .line div {
  width: 80%;
}

@media screen and (min-width: 720px) and (max-width: 1300px) {
  /* #BodyContent {
    height:0%;
  } */
  .textPara {
    line-height: 1.6;
  }
}

@media screen and (min-width: 1900px) {
  .textPara {
    line-height: 2.2;
  }
}
