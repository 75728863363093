.sidebar {
  position: fixed;
  top: 0;
  right: -300px; /* Width of the sidebar */
  width: 300px;
  height: 100%;
  background-color: white;
  color: black;
  transition: right 0.3s ease;
  z-index: 1000;
  scrollbar-width: none;
  overflow: scroll;
}

.sidebar.open {
  right: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Container styling */
.description-container {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

/* Unordered list styling */
.description-list {
  list-style-type: none;
  font-weight: 500;
}

.description-list li {
  padding: 5px 0;
  font-size: 16px;
  font-weight: 500;
}

/* Nested list styling */
.description-list .nested-list {
  list-style-type: none;
  font-weight: 500;
  padding-left: 10px;
}

/* Link styling */
.description-link {
  color: #007bff;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: justify;
  font-weight: 500;
}

.description-link:hover {
  text-decoration: underline;
}

/* Paragraph styling */
.description-paragraph {
  margin-top: 10px;
  font-size: 14px;
  text-align: justify;
  font-weight: 500;
}

.description-list-element {
  font-size: 14px;
}

.description-name {
  text-align: center;
  padding: 10px;
}

.description-close-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-close-button {
  width: 70px;
  height: 40px;
  color: white;
  background-color: #675496;
  border-radius: 5px;
  border: none;

  text-align: center;
}


